$blue: #1c0349;
$accent: #12abb6;

.project-dashboard {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  z-index: 3;

  @media only screen and (max-width: 768px) {
    min-height: auto;
    height: auto;
    max-height: auto;
  }

  .iframeCinema {
    position: fixed;
    z-index: 998;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    background-color: #17003d8c;

    .iframeWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 0;
      z-index: 9998;
      width: 100vw;
      height: 100vh;

      .loading-project {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%);
        z-index: 999;
        color: white;
        font-size: 3rem;
        background-color: #000000d2;
        text-align: center;
        padding: 2rem 1rem;
        width: 100%;
      }

      iframe {
        z-index: 9997;
      }

      @media only screen and (max-width: 768px) {
        width: 100vw;
        height: 100vh;

        p {
          right: 16%;
        }
      }

      .exitButton {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: fixed;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999999;
        color: white;
        padding: 0.75rem;
        margin: 0;
        background-color: #19005e;
        border: none;
        color: white;
        cursor: pointer;
        font-variant: small-caps;
        font-size: 1rem;
        width: 50px;

        span {
          font-size: 1.5rem;
          padding-right: 3px;
        }

        &:hover {
          transition: 300ms;
          background-color: #230770;
        }
      }
    }
  }

  .side-bar {
    display: flex;
    justify-content: center;
    width: 15vw;
    height: 100vh;
    max-height: 100vh;
    background-color: black;
    border-right: 2px solid;
    border-image: linear-gradient(0deg, $accent, $blue 100%);
    border-image-slice: 1;

    @media only screen and (max-width: 1440px) {
      width: 19vw;
    }

    @media only screen and (max-width: 1080px) {
      width: 25vw;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }

    .side-bar-items-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-top: 200px;

      .side-bar-items {
        margin-bottom: 15px;
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border-radius: 10px;
        color: #fff;
        padding: 30px 0px;
        font-size: 1.5rem;

        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          .code-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            min-width: 100%;
            max-width: 100%;
            min-height: 75px;
            max-height: 75px;
            z-index: 8;
            fill: #fff;
            padding: 16px 0px;
            cursor: pointer;
          }

          &:hover {
            -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.57);
            -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.57);
            box-shadow: 10px 10px 5px 0px rgba(11, 11, 31, 0.57);
            -webkit-box-shadow: inset 2px 2px 17px -5px rgb(145, 145, 145);
            -moz-box-shadow: inset 2px 2px 17px -5px rgb(145, 145, 145);
            box-shadow: inset 2px 2px 17px -5px rgb(31, 26, 58);
          }
        }

        cursor: pointer;
      }
    }
  }

  .widget-screen-container {
    position: relative;
    width: 85vw;
    max-width: 85vw;
    height: 100vh;
    max-height: auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background-color: black;
    padding: 3%;
    background-repeat: no-repeat;
    background-size: cover;

    @media only screen and (max-width: 1440px) {
      width: 81vw;
      max-width: 81vw;
    }

    @media only screen and (max-width: 1080px) {
      width: 75vw;
      max-width: 75vw;
    }

    @media only screen and (max-width: 768px) {
      height: auto;
      max-height: auto;
      width: 100vw;
      max-width: 100vw;
      padding: 2%;
      padding-top: 30px;
      scroll-snap-align: start;
    }

    @media only screen and (max-width: 600px) {
      padding-top: 80px;
    }

    @media only screen and (max-width: 480px) {
      padding-top: 110px;
    }

    @media only screen and (max-width: 375px) {
      padding-top: 130px;
    }

    .filterWrapper {
      position: absolute;
      left: 2%;
      top: 2%;
      z-index: 10;
      display: flex;
      gap: 4%;
      width: 100%;
      box-sizing: border-box;

      @media only screen and (max-width: 768px) {
        left: 0%;
        top: 90px;
        z-index: 10;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding: 0px;
        width: 100%;
        gap: 0%;
      }

      .filterFolioCard {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        min-height: 46px;
        height: auto;
        background-color: rgb(255, 255, 255);
        color: white;
        font-weight: 500;
        padding: 5px 20px;
        letter-spacing: 0.05rem;
        cursor: pointer;
        font-size: 1.2rem;
        background-color: #7c006a;
        position: absolute;
        right: 0;
        top: -90px;

        @media only screen and (max-width: 550px) {
          top: -93px;
        }

        @media only screen and (max-width: 480px) {
          top: -90px;
        }
      }

      .filterComm,
      .filterConcept,
      .allCards {
        background-color: transparent;
        color: white;
        font-weight: 500;
        border-radius: 10px;
        padding: 5px 20px;
        font-size: 1.6rem;
        letter-spacing: 0.05rem;
        cursor: pointer;

        &.active {
          background: linear-gradient(80deg, $blue, $accent);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }

        @media only screen and (max-width: 768px) {
          display: flex;
          gap: 0%;
        }

        &.mobileActive {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          min-height: 46px;
          height: auto;
          background-color: rgb(255, 255, 255);
          color: black;
          font-weight: 500;
          font-size: 1.2rem;
          padding: 0.5rem 1rem;
          border-radius: 0px;
        }


        &.mobileInactive {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          min-height: 46px;
          height: auto;
          font-weight: 500;
          background-color: rgb(0, 0, 0);
          fill: white !important;
          font-size: 1.2rem;
          border: 2px solid white;
          padding: 0.5rem 1rem;
          border-radius: 0px;
        }
      }
    }

    .widget-screen-opacity {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 125%;
      background-color: rgba(0, 0, 0, 0.6);
      /* Adjust opacity value as needed */
      pointer-events: none;

      /* Allows clicks to pass through this element */
      @media only screen and (max-width: 768px) {
        height: auto;
      }
    }

    .widget-screen {
      display: flex;
      flex-wrap: wrap;
      padding: 7% 3% 3% 3%;
      gap: 100px;
      width: 100%;
      max-width: 100%;
      background-color: transparent;

      @media only screen and (max-width: 1440px) {
        padding-top: 10%;
      }

      @media only screen and (max-width: 1080px) {
        padding-top: 13%;
      }

      @media only screen and (max-width: 940px) {
        padding-top: 15%;
      }

      @media only screen and (max-width: 768px) {
        padding: 1% 0% 40%;
        margin-top: 30%;
        margin-bottom: 30%;
        justify-content: center;
        align-items: center;
        flex-wrap: initial;
        flex-direction: column;
        scroll-snap-align: start;
      }

      .project-widget {
        display: flex;
        min-width: 250px;
        min-height: 180px;
        max-width: 250px;
        max-height: 180px;
        border-radius: 5px;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.57);
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.57);
        -webkit-box-shadow: inset 2px 2px 17px -5px rgb(145, 145, 145);
        -moz-box-shadow: inset 2px 2px 17px -5px rgb(145, 145, 145);
        box-shadow: inset 2px 2px 17px -5px rgb(145, 145, 145);
        cursor: pointer;
        background: linear-gradient(234deg, $blue 0%, $blue 50%, $accent 100%);

        @media only screen and (max-width: 1440px) {
          min-width: 180px;
          min-height: 130px;
          max-width: 180px;
          max-height: 130px;
        }

        @media only screen and (max-width: 768px) {
          min-width: 60vw;
          min-height: 180px;
          max-width: 60vw;
          max-height: 180px;
        }

        @media only screen and (max-width: 600px) {
          min-height: 130px;
          max-height: 130px;
        }

        .widget-body {
          display: flex;
          height: 100%;
          border-radius: 5px;
          width: 100%;

          .widget-left {
            display: flex;
            height: 100%;
            width: 45%;

            .widget-image {
              width: auto;
              height: 100%;
              object-fit: scale-down;
              border-radius: 5px 0px 0px 5px;
            }

            @media only screen and (max-width: 768px) {
              width: 50%;

              .widget-image {
                width: 100%;
                height: 100%;
              }
            }

            @media only screen and (max-width: 500px) {
              width: 45%;

              .widget-image {
                width: auto;
                height: 100%;
              }
            }
          }

          .widget-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: right;
            height: 100%;
            width: auto;
            gap: 5%;
            padding-right: 20px;
            margin-left: auto;

            @media only screen and (max-width: 768px) {
              width: 50%;
            }

            @media only screen and (max-width: 500px) {
              width: auto;
            }

            &.fullWidgetRight {
              width: 100%;
              padding-inline: 10px;
              text-align: center;
            }

            .widget-heading {
              display: flex;
              font-size: 1.3rem;
              font-weight: 500;
              color: white;
              text-align: right;
              margin-left: auto;
              width: 100%;

              @media only screen and (max-width: 1440px) {
                font-size: 0.95rem;
              }

              @media only screen and (max-width: 768px) {
                font-size: 1.3rem;
              }

              @media only screen and (max-width: 550px) {
                font-size: 0.95rem;
              }

              &.fullWidgetHeading {
                font-size: 1.4rem;
                font-weight: 600;
                width: 100%;
                text-align: center;
                align-items: center;
                justify-content: center;

                @media only screen and (max-width: 768px) {
                  font-size: 1.4rem;
                }

                @media only screen and (max-width: 550px) {
                  font-size: 1.1rem;
                }

                @media only screen and (max-width: 1440px) {
                  font-size: 1.1rem;
                }
              }
            }

            .widget-description {
              display: flex;
              text-align: left;
              width: 85%;
              font-size: 0.9rem;
              color: white;
              font-size: 500;

              @media only screen and (max-width: 550px) {
                font-size: 0.9rem;
              }

              @media only screen and (max-width: 768px) {
                font-size: 1rem;
              }

              @media only screen and (max-width: 1440px) {
                font-size: 0.6rem;
              }

              &.fullWidgetSubHeading {
                width: 100%;
                text-align: center;
                align-items: center;
                justify-content: center;
              }
            }

            .widget-open-iframe {
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 5px 0px 5px 0px;
              width: 35%;
              text-align: center;
              color: #fff;
              background-color: #00000054;
              padding: 8px;
              font-size: 0.7rem;
              cursor: pointer;

              &:hover {
                background-color: transparent;
                color: white;
                transition: 0.3s ease-in-out;
                -webkit-box-shadow: inset 1px 2px 17px -5px rgba(0, 0, 0, 0.349);
                -moz-box-shadow: inset 1px 2px 17px -5px rgba(0, 0, 0, 0.301);
                box-shadow: inset 1px 2px 17px -5px rgba(255, 255, 255, 0.329);
              }

              @media only screen and (max-width: 1440px) {
                font-size: 0.65rem;
              }
            }

            .widget-open-ext {
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 0px 5px 0px 5px;
              width: 35%;
              text-align: center;
              color: #fff;
              background-color: #00000054;
              padding: 8px;
              font-size: 0.7rem;
              cursor: pointer;

              @media only screen and (max-width: 1440px) {
                font-size: 0.65rem;
              }

              &:hover {
                background-color: transparent;
                color: white;
                transition: 0.3s ease-in-out;
                -webkit-box-shadow: inset 1px 2px 17px -5px rgba(0, 0, 0, 0.349);
                -moz-box-shadow: inset 1px 2px 17px -5px rgba(0, 0, 0, 0.301);
                box-shadow: inset 1px 2px 17px -5px rgba(255, 255, 255, 0.329);
              }
            }
          }
        }
      }

      .project-widget-sandBox {
        display: flex;
        min-width: 250px;
        min-height: 180px;
        max-width: 250px;
        max-height: 180px;
        border-radius: 5px;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.57);
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.57);
        -webkit-box-shadow: inset 2px 2px 17px -5px rgb(145, 145, 145);
        -moz-box-shadow: inset 2px 2px 17px -5px rgb(145, 145, 145);
        box-shadow: inset 2px 2px 17px -5px rgb(145, 145, 145);
        cursor: pointer;
        background: linear-gradient(234deg, $blue 0%, $blue 50%, $accent 100%);

        @media only screen and (max-width: 1440px) {
          min-width: 180px;
          min-height: 130px;
          max-width: 180px;
          max-height: 130px;
        }

        @media only screen and (max-width: 768px) {
          min-width: 60vw;
          min-height: 180px;
          max-width: 60vw;
          max-height: 180px;
        }

        @media only screen and (max-width: 370px) {
          min-height: 130px;
          max-height: 130px;
        }

        .widget-body {
          display: flex;
          height: 100%;
          border-radius: 5px;
          width: 100%;

          .widget-left {
            display: flex;
            height: 100%;
            width: 45%;

            .widget-image {
              width: auto;
              height: 100%;
              object-fit: scale-down;
              border-radius: 5px 0px 0px 5px;
            }

            @media only screen and (max-width: 768px) {
              width: 50%;

              .widget-image {
                width: 100%;
                height: 100%;
              }
            }

            @media only screen and (max-width: 500px) {
              width: 45%;

              .widget-image {
                width: auto;
                height: 100%;
              }
            }
          }

          .widget-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: right;
            height: 100%;
            width: auto;
            gap: 5%;
            padding-right: 20px;
            margin-left: auto;

            @media only screen and (max-width: 768px) {
              width: 50%;
            }

            @media only screen and (max-width: 500px) {
              width: auto;
            }

            &.fullWidgetRight {
              width: 100%;
              padding-inline: 10px;
              text-align: center;
            }

            .widget-heading {
              display: flex;
              font-size: 1.3rem;
              font-weight: 400;
              color: white;
              text-align: right;
              margin-left: auto;
              width: 100%;

              @media only screen and (max-width: 1440px) {
                font-size: 0.95rem;
              }

              @media only screen and (max-width: 768px) {
                font-size: 1.3rem;
              }

              @media only screen and (max-width: 550px) {
                font-size: 1.1rem;
              }

              &.fullWidgetHeading {
                font-size: 1.4rem;
                font-weight: 500;
                width: 100%;
                text-align: center;
                align-items: center;
                justify-content: center;

                @media only screen and (max-width: 768px) {
                  font-size: 1.4rem;
                }

                @media only screen and (max-width: 550px) {
                  font-size: 1.1rem;
                }

                @media only screen and (max-width: 1440px) {
                  font-size: 1.1rem;
                }
              }
            }

            .widget-description {
              display: flex;
              text-align: left;
              width: 85%;
              font-size: 0.9rem;
              color: white;
              font-size: 500;

              @media only screen and (max-width: 550px) {
                font-size: 0.9rem;
              }

              @media only screen and (max-width: 768px) {
                font-size: 1rem;
              }

              @media only screen and (max-width: 1440px) {
                font-size: 0.6rem;
              }

              &.fullWidgetSubHeading {
                width: 100%;
                text-align: center;
                align-items: center;
                justify-content: center;
              }
            }

            .widget-open-iframe {
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 5px 0px 5px 0px;
              width: 35%;
              text-align: center;
              color: #fff;
              background-color: #00000054;
              padding: 5px;
              font-size: 0.7rem;
              cursor: pointer;

              @media only screen and (max-width: 768px) {
                font-size: 0.7rem;
              }

              @media only screen and (max-width: 1440px) {
                font-size: 0.5rem;
              }

              &:hover {
                background-color: transparent;
                color: white;
                transition: 0.3s ease-in-out;
                -webkit-box-shadow: inset 1px 2px 17px -5px rgba(0, 0, 0, 0.349);
                -moz-box-shadow: inset 1px 2px 17px -5px rgba(0, 0, 0, 0.301);
                box-shadow: inset 1px 2px 17px -5px rgba(255, 255, 255, 0.329);
              }
            }

            .widget-open-ext {
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 0px 5px 0px 5px;
              width: 35%;
              text-align: center;
              color: #fff;
              background-color: #00000054;
              padding: 5px;
              font-size: 0.7rem;
              cursor: pointer;

              @media only screen and (max-width: 768px) {
                font-size: 0.7rem;
              }

              @media only screen and (max-width: 1440px) {
                font-size: 0.5rem;
              }

              &:hover {
                background-color: transparent;
                color: white;
                transition: 0.3s ease-in-out;
                -webkit-box-shadow: inset 1px 2px 17px -5px rgba(0, 0, 0, 0.349);
                -moz-box-shadow: inset 1px 2px 17px -5px rgba(0, 0, 0, 0.301);
                box-shadow: inset 1px 2px 17px -5px rgba(255, 255, 255, 0.329);
              }
            }
          }
        }
      }
    }
  }
}