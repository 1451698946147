$orange: #1c0349;
$red: #241b3d;
$accent: #0b7b83;
// $orange: #fcaa3d;
// $red: #ed452e;

.site-container {
  display: flex;
  position: relative;
  justify-content: center;
  min-height: 5000px;
  max-height: 5000px;
  flex: 1;
  background-color: #04050e;
  z-index: 0;
  overflow: hidden;

  &.reduced {
    min-height: 5000px;
    max-height: 5000px;
  }

  .exitButtonMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 4;
    color: white;
    padding: 1rem 2rem;
    margin: 0;
    background-color: $red;
    border: none;
    color: white;
    cursor: pointer;
    font-variant: small-caps;
    font-size: 1.2rem;

    span {
      font-size: 1.5rem;
      padding-right: 3px;
    }

    &:hover {
      transition: 300ms;
      background-color: $orange;
    }
  }

  .site-wrapper {
    position: relative;
    margin-top: 3rem;
    width: 80vw;
    height: auto;
    color: white;
    box-sizing: border-box;

    @media only screen and (max-width: 800px) {
      width: 90vw;
    }

    .Heading {
      position: absolute;
      top: 100px;
      left: 0px;
      font-size: 6rem;
      letter-spacing: 6px;
      box-sizing: border-box;

      @media only screen and (max-width: 576px) {
        font-size: 5rem;
        text-align: center;
        width: 100%;
      }

      @media only screen and (max-width: 490px) {
        font-size: 4.5rem;
      }

      @media only screen and (max-width: 400px) {
        font-size: 3.5rem;
      }

      @media only screen and (max-width: 360px) {
        font-size: 3rem;
      }

      @media only screen and (max-width: 310px) {
        font-size: 2.3rem;
      }
    }

    .Subheading {
      display: flex;
      white-space: nowrap;
      width: auto;
      top: 550px;
      right: 150px;
      position: absolute;
      font-size: 4.5rem;
      letter-spacing: 6px;

      .Dot {
        color: $accent;
      }

      @media only screen and (max-width: 1440px) {
        font-size: 4rem;
      }

      @media only screen and (max-width: 1350px) {
        font-size: 3.5rem;
      }

      @media only screen and (max-width: 1066px) {
        display: none;
      }
    }

    .SubheadingMobile {
      display: none;

      @media only screen and (max-width: 1067px) {
        display: initial;
        position: absolute;
        width: 100%;
        text-align: center;
        top: 400px;
        font-size: 5rem;

        .Dot {
          padding-inline: 2px;
          color: $accent;
          font-size: 4.5rem;
        }
      }

      @media only screen and (max-width: 490px) {
        font-size: 4.5rem;

        .Dot {
          font-size: 4.5rem;
        }
      }

      @media only screen and (max-width: 400px) {
        font-size: 3.5rem;

        .Dot {
          font-size: 3.5rem;
        }
      }

      @media only screen and (max-width: 360px) {
        font-size: 3rem;

        .Dot {
          font-size: 3rem;
        }
      }

      @media only screen and (max-width: 310px) {
        font-size: 2.3rem;

        .Dot {
          font-size: 2.3rem;
        }
      }
    }

    .ArtCircleTop {
      position: absolute;
      top: 550px;
      right: 60%;
      border-radius: 50%;
      width: 1000px;
      height: 700px;
      filter: blur(100px);
      rotate: 260deg;
      background: linear-gradient(190deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(61, 21, 28, 0.1) 35%,
          $red 80%,
          $orange 100%);
      animation: easeInOutAnimation 7s infinite;
      animation-timing-function: ease-in-out;
    }

    @keyframes easeInOutAnimation {
      0% {
        opacity: 1;
        transform: rotate(0deg);
      }

      50% {
        opacity: 0.2;
        transform: rotate(50deg);
      }

      100% {
        opacity: 1;
        transform: rotate(0deg);
      }
    }

    .Headline {
      top: 1100px;
      left: 0px;
      position: absolute;
      font-size: 6rem;

      .fade-text {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
      }

      .fade-text.active {
        opacity: 1;
      }

      @media only screen and (max-width: 1200px) {
        width: 100%;
        font-size: 5rem;
      }

      @media only screen and (max-width: 700px) {
        width: 100%;
        font-size: 4rem;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      @media only screen and (max-width: 500px) {
        width: 100%;
        font-size: 3.5rem;
      }

      @media only screen and (max-width: 420px) {
        width: 100%;
        font-size: 3.1rem;
      }

      @media only screen and (max-width: 380px) {
        font-size: 2.5rem;
      }
    }

    @keyframes pulseAnimation {
      0% {
        filter: brightness(1.7);
        /* Initial brightness */
      }

      50% {
        filter: brightness(3);
        /* Brighter color */
      }

      100% {
        filter: brightness(1.7);
        /* Back to original brightness */
      }
    }

    .Headline2 {
      top: 400px;
      left: 630px;
      animation: pulseAnimation 5s linear infinite;
      position: absolute;
      font-size: 6rem;
      width: 75vw;
      color: #fff;
      background: linear-gradient(80deg, $red, $accent);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;


      @media only screen and (max-width: 1600px) {
        left: 530px;
      }

      @media only screen and (max-width: 1500px) {
        left: 430px;
      }

      @media only screen and (max-width: 1400px) {
        left: 350px;
      }

      @media only screen and (max-width: 1300px) {
        left: 200px;
      }

      @media only screen and (max-width: 1200px) {
        width: 100%;
        right: 0;
        left: 0;
        font-size: 5rem;
      }

      @media only screen and (max-width: 700px) {
        width: 100%;
        font-size: 4rem;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      @media only screen and (max-width: 570px) {
        width: 100%;
        font-size: 3.1rem;
      }

      @media only screen and (max-width: 510px) {
        width: 100%;
        font-size: 3.3rem;
      }

      @media only screen and (max-width: 470px) {
        width: 100%;
        font-size: 2.8rem;
      }

      @media only screen and (max-width: 420px) {
        font-size: 2.5rem;
      }
      @media only screen and (max-width: 360px) {
        font-size: 2.1rem;
      }

    }

    .Projects-Container {
      position: absolute;
      top: 2400px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ArtCircleBottom {
      position: absolute;
      top: 2200px;
      right: 6%;
      border-radius: 50%;
      width: 600px;
      height: 600px;
      filter: blur(300px);
      rotate: 200deg;
      background: rgb(2, 0, 36);
      background: linear-gradient(190deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(61, 21, 28, 0.1) 35%,
          $red 80%,
          $accent 100%);
    }

    .Projects {
      top: 2200px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      font-size: 4rem;
      width: auto;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media only screen and (max-width: 430px) {
        top: 2100px;
        font-size: 3.5rem;
      }

      .arrow-container {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 30px solid $accent;
        /* Change color as needed */
        position: relative;
        margin: 20px 0px;
      }
    }

    .Button {
      top: 3200px;
      right: 43%;
      position: absolute;
      font-size: 1.7rem;
      padding: 20px;
      border: 3px solid white;
      border-radius: 5px;
      background: linear-gradient(210deg, $accent, white);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      cursor: pointer;

      @media only screen and (max-width: 1000px) {
        right: 32%;
        text-align: center;
      }

      @media only screen and (max-width: 900px) {
        right: 35%;
      }

      @media only screen and (max-width: 800px) {
        right: 50%;
        transform: translateX(50%);
        text-align: center;
      }

      @media only screen and (max-width: 550px) {
        font-size: 1.2rem;
      }

      &:hover {
        transition: 1s;
        border: 3px solid transparent;
        filter: brightness(3);
      }
    }

    .Contact-Form-Wrapper {
      position: absolute;
      top: 3660px;
      right: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: auto;
      gap: 16px;
      box-sizing: border-box;

      .contact-heading-wrapper {
        display: flex;
        flex-direction: column;
        font-size: 2.8rem;
        margin-bottom: 70px;
        text-align: left;
        width: 300px;

        @media only screen and (max-width: 580px) {
          font-size: 2.2rem;
        }

        .contact-error-email {
          font-size: 1.2rem;

          span {
            cursor: pointer;

            &:hover {
              color: $red;
            }
          }

          @media only screen and (max-width: 580px) {
            font-size: 2.2rem;
          }
        }
      }

      @media only screen and (max-width: 650px) {
        width: 100%;
        right: 0;
        left: 50%;
        transform: translateX(-50%);
        top: 3500px;
      }

      .Form-Name {
        font-size: 1.5rem;
        padding: 20px;
        border: 1.9px solid #fff;
        border-radius: 5px;
        width: 300px;
        color: white;
        background-color: transparent;
        box-sizing: border-box;

        @media only screen and (max-width: 300px) {
          width: 100%;
        }

        &::placeholder {
          color: white;
        }
      }

      .Form-Email {
        font-size: 1.5rem;
        padding: 20px;
        border: 1.9px solid #fff;
        border-radius: 5px;
        width: 300px;
        color: white;
        background-color: transparent;
        box-sizing: border-box;

        @media only screen and (max-width: 300px) {
          width: 100%;
        }

        &::placeholder {
          color: white;
        }
      }

      .Form-Input {
        font-size: 1.5rem;
        padding: 20px;
        border: 1.9px solid #fff;
        border-radius: 5px;
        width: 300px;
        color: white;
        min-height: 100px;
        max-height: 100px;
        background-color: transparent;
        box-sizing: border-box;

        @media only screen and (max-width: 300px) {
          width: 100%;
        }

        &::placeholder {
          color: white;
        }
      }

      .submit-button-wrapper {
        display: flex;
        width: 300px;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;

        @media only screen and (max-width: 300px) {
          width: 100%;
        }

        .submitButton {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: 50px;
          border-radius: 200px;
          background-color: transparent;
          font-size: 1.5rem;
          border: 1.9px solid white;
          color: white;
          padding: 4px 24px;

          @media only screen and (max-width: 580px) {
            left: 0;
            right: 0;
            justify-content: end;
            text-align: right;
          }

          &:hover {
            transition: 0.5s;
            background: linear-gradient(80deg, $accent, white);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }
        }
      }

      .errorPopUpN,
      .errorPopUpE {
        padding-left: 2%;
        font-size: 0.9rem;
        width: 300px;
        color: white;
      }
    }

    .socials {
      top: 3660px;
      left: 10px;
      height: 100%;
      position: absolute;
      width: 310px;
      max-width: 100%;

      .connect {
        font-size: 2.8rem;
        margin-bottom: 70px;

        @media only screen and (max-width: 580px) {
          font-size: 2.2rem;
        }
      }

      @media only screen and (max-width: 660px) {
        top: 4200px;
        left: 50%;
        transform: translate(-50%);

      }

      .Linkedin {
        display: flex;
        align-items: center;
        text-align: left;
        height: 100px;
        font-size: 1.8rem;
        cursor: pointer;
        letter-spacing: 2px;

        @media only screen and (max-width: 310px) {
          font-size: 1.7rem;
        }

        .svg-wrapper {
          display: flex;
          min-width: 70px;
          max-width: 70px;

          svg {
            min-width: 60px;
            width: 60px;
          }
        }

        .svg-wrapper-figma {
          display: flex;
          min-width: 70px;
          max-width: 70px;

          svg {
            border-radius: 50%;
            min-width: 51.6px;
            width: 51.6px;
          }
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          margin: 0;
          height: 100%;
          margin-left: 10px;
          color: white;
          text-decoration: none;

          &:hover {
            transition: 1s;
            background: linear-gradient(80deg, $accent, white);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }
        }
      }
    }
  }

  .ArtLineFooter {
    position: absolute;
    top: 4700px;
    left: 0px;
    width: 100vw;
    height: 10px;
    border: none;
    filter: blur(20px);
    background: linear-gradient(190deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(61, 21, 28, 0.1) 35%,
        $red 80%,
        $orange 100%);
  }
}