.cardContainer {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  z-index: 2;
  box-sizing: border-box;
  display: flex;

  .swipe {
    position: absolute;
    box-sizing: border-box;
    width: 100%;

    .card {
      z-index: 5111;
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      color: black;
      background-color: #333333;
      width: 100%;
      max-width: 1100px;
      height: 600px;
      box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
      border-radius: 20px;
      background-size: cover;
      background-position: center;
      box-sizing: border-box;

      .heading-wrap {
        pointer-events: none;
        background-color: rgba(34, 34, 34, 0.8);
        height: auto;
        width: 100%;
        padding: 0rem;
        border-radius: 0px 0px 15px 15px;
        position: absolute;
        bottom: 0;
        padding-top: 1rem;
        box-sizing: border-box;

        h3 {
          letter-spacing: 4px;
          color: #ffffff;
          background: linear-gradient(80deg, #21ccd8, #0b7b83);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          font-size: 3rem;
          padding-left: 2rem;
          margin: 0;

          @media only screen and (max-width: 700px) {
            font-size: 2.5rem;
          }

          @media only screen and (max-width: 500px) {
            font-size: 2rem;
          }

          @media only screen and (max-width: 400px) {
            font-size: 1.9rem;
          }
        }

        p {
          pointer-events: none;
          width: auto;
          padding: 0.4rem 2rem;
          border-radius: 15px;
          display: flex;
          flex-wrap: wrap;
          color: white;
          gap: 1rem;
          max-width: 100%;
          box-sizing: border-box;
          font-weight: 400;
          font-size: 1.75rem;
          width: calc(100% - 320px);

          @media only screen and (max-width: 1366px) {
            font-size: 1.4rem;
          }

          @media only screen and (max-width: 1000px) {
            width: 100%;
          }

          @media only screen and (max-width: 700px) {
            font-size: 1.3rem;
          }

          @media only screen and (max-width: 500px) {
            font-size: 1.2rem;
          }

          @media only screen and (max-width: 400px) {
            font-size: 1rem;
          }

          span {
            font-size: 1.75rem;
            font-weight: 200;


            @media only screen and (max-width: 1366px) {
              font-size: 1.4rem;
            }

            @media only screen and (max-width: 700px) {
              font-size: 1.25rem;
            }

            @media only screen and (max-width: 500px) {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      position: absolute;
      right: 10px;
      bottom: 30px;
      width: auto;
      height: auto;
      z-index: 999999;

      @media only screen and (max-width: 1000px) {
        bottom: -100px;
        right: 0px;
      }

      @media only screen and (max-width: 600px) {
        display: none;
      }

      button {
        background-color: rgba(255, 255, 255);
        flex-shrink: 0;
        padding: 10px;
        border-radius: 100px;
        color: #0b7b83;
        font-size: 18px;
        border: 3px solid #0b7b83;
        transition: 200ms;
        cursor: pointer;
        margin: 10px 0px;
        font-weight: bold;
        width: 120px;
        height: 60px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

        &:hover {
          transition: 1s;
          color: #0f9099;
          background-color: #04050e;
        }
      }
    }

    .buttons-mobile {
      display: flex;
      position: absolute;
      color: white;
      bottom: -100px;
      width: 100%;
      justify-content: flex-end;
      font-weight: 400;

      @media only screen and (min-width: 600px) {
        display: none;
      }
      
      button {
        background-color: rgba(255, 255, 255);
        flex-shrink: 0;
        padding: 10px;
        border-radius: 100px;
        color: #0b7b83;
        font-size: 18px;
        border: 3px solid #0b7b83;
        transition: 200ms;
        cursor: pointer;
        margin: 10px 0px;
        font-weight: bold;
        width: 120px;
        height: 60px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

        &:hover {
          transition: 1s;
          color: #0f9099;
          background-color: #04050e;
        }
      }
    }
  }

  .buttonR {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    flex-shrink: 0;
    padding: 10px;
    border-radius: 100px;
    color: #0b7b83;
    font-size: 18px;
    border: 3px solid #0b7b83;
    transition: 200ms;
    cursor: pointer;
    margin: 100px auto;
    font-weight: bold;
    width: 120px;
    height: 60px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      transition: 1s;
      color: #0f9099;
      border: 3px solid transparent;
      background-color: transparent;
    }
  }
}

.swipe:last-of-type {}